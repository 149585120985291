import React, { useEffect, useState } from "react";
import axios from "../axios";
import {
  Drawer,
  Tabs,
  Form,
  Row,
  Col,
  Input,
  Select,
  Space,
  Button,
  Flex,
  Checkbox,
  Alert,
  Table,
  Tag,
  notification,
  Radio,
  Switch,
  Card,
} from "antd";
import { studentList } from "../states/student";
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import { useAtom } from "jotai";
import { citysList } from "../states/citys";
import Autocomplete from "react-google-autocomplete";
import { academicLevels, courseCategories } from "../constants/tutorConstants";
import EscalationSwitch from './escalationSwitch';
import ActivityTab from './ActivityTab';
import { useNavigate, useLocation } from 'react-router-dom';
import './StudentDrawer.css';
import log from '../logger'; 

const OpenActivities = {
  ACTIVITY_1: { id: 1, description: 'Open activity 1' },
  ACTIVITY_2: { id: 2, description: 'Open activity 2' },
};

const ResolvedActivities = {
  ACTIVITY_1: { id: 3, description: 'Resolved activity 1' },
  ACTIVITY_2: { id: 4, description: 'Resolved activity 2' },
};

const { TabPane } = Tabs;
const { Option } = Select; 

function StudentDrawer({ id, open, setOpen }) {
  const [citys, setCitys] = useAtom(citysList);
  const [studentListData, setStudentListData] = useAtom(studentList);
  const [data, setData] = useState({});
  const [activeEdit, setActiveEdit] = useState("");
  const [address, setAddress] = useState({});
  const [filterForm] = Form.useForm();
  const [selectedCourseCategory, setSelectedCourseCategory] = useState(null);
  const [streamsData, setStreamsData] = useState([]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [customAmountRequest, setCustomAmountRequest] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle,setToggle] = useState()
  const [searchTerm, setSearchTerm] = useState('');

  const [escalationChecked, setEscalationChecked] = useState(false);
  const [selectedExecutive, setSelectedExecutive] = useState(null);

  const [openActivities, setOpenActivities] = useState([]);
  const [resolvedActivities, setResolvedActivities] = useState([]);


  const updateURL = (filters) => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        searchParams.append(key, value);
      }
    });
    if (searchTerm) {
      searchParams.append('search', searchTerm);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleFilterChange = (allValues) => {
    const filtersWithoutSearch = { ...allValues };
    delete filtersWithoutSearch.search; // Remove search from filters
    log.debug("Filter values:", filtersWithoutSearch);
    setAppliedFilters(filtersWithoutSearch);
    updateURL(filtersWithoutSearch);
  };

  const applyFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const filtersFromURL = {};
    searchParams.forEach((value, key) => {
      if (key === 'search') {
        setSearchTerm(value);
      } else {
        filtersFromURL[key] = value;
      }
    });
    setAppliedFilters(filtersFromURL);
    form.setFieldsValue(filtersFromURL);
  };

  useEffect(() => {
    applyFiltersFromURL();
  }, [location.search]);

  const handleClearAll = () => {
    log.debug("Clearing all filters");
    setAppliedFilters({});
    setSearchTerm('');
    form.resetFields();
    navigate(location.pathname);
  };

  const handleTagClose = (filterKey) => {
    const { [filterKey]: omit, ...rest } = appliedFilters;
    setAppliedFilters(rest);
    form.setFieldsValue({ [filterKey]: undefined });
    updateURL(rest);
  };

  const shouldDisplayFilter = (value) => {
    return value !== null && value !== undefined && value !== '';
  };

  const updatePersonalDetails = (data) => {
    axios
      .post("/v1/cre/tutor/personal-details", { ...data })
      .then((res) => {
        modifyTutorsListWithUpdatedUserData();
        setActiveEdit("");
      })
      .catch((err) => {
        alert(err.response?.data?.message || "Failed to update personal details");
      });
  };

  const modifyTutorsListWithUpdatedUserData = () => {
    return;
    try {
      axios
        .get(`/v1/cre/tutor?tutorId=${id}`)
        .then((res) => {
          // setTutors((pre) => {
          //   let updatedResult = pre.map((tutorRecord) => {
          //     if (tutorRecord.id === id) return { ...res.data.data.Result[0] };
          //     return tutorRecord;
          //   });
          //   return updatedResult;
          // });
          setOpen(true);
        })
        .catch((err) => {});
    } catch (error) {}
  };
  const fetchCoursesOfCategory = async (category_id) => {
     log.debug(category_id);
    setStreamsData([]);
    axios
      .get("/v1/student/all-courses", { params: { category_id } })
      .then((response) => {
        setStreamsData(response.data);
      })
      .catch((error) => {});
  };

  const fetchDataSetDefaults = async () => {
    try {
      await fetchCoursesOfCategory(data?.category_id);
      setSelectedCourseCategory(data?.category_id);
    } catch (error) {}
  };

  const fetchPaymentDetails = async () => {
    try {
      const user_id = data?.userId;
      axios
        .get(`/v1/cre/user/payment-details/${user_id}`, {
          params: { dealId: id },
        })
        .then((response) => {
          const { data, is_success } = response.data;

          if (is_success) {
            setPaymentDetails(data);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const emailInitialSubcriptionPayment = async () => {
    try {
      const user_id = data?.userId;
      axios
        .get(`/v1/cre/student/payment-subscription-invoice/${user_id}`, {
          params: {
            dealId: id,
          },
        })
        .then((response) => {
          notification.success({
            message: "Send Payment Link",
            description: "Payment link sent to " + data?.email + " email",
            duration: 2,
          });
          fetchPaymentDetails();
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const emailCutomAmountPayment = async (values) => {
    try {
      const user_id = data?.userId;
      axios
        .post(`/v1/cre/student/payment-order-invoice/${user_id}`, {
          ...values,
          dealId: id,
        })
        .then((response) => {
          const { is_success } = response.data;
          if (is_success) {
            notification.success({
              message: "Order Email",
              description: " Order Email Sent Successfully",
              duration: 2,
            });
          }
          fetchPaymentDetails();
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error,
            duration: 2,
          });
        });
      setCustomAmountRequest(false);
    } catch (error) {}
  };

  const sendPaymentRecipt = async (paymentId) => {
    try {
      axios
        .post(`/v1/cre/student/payment-reciept`, {
          paymentId,
        })
        .then((response) => {
          const { is_success } = response.data;
          if (is_success) {
            notification.success({
              message: "Send Receipt",
              description: "Receipt sent to your email",
              duration: 2,
            });
          }
        });
    } catch (error) {}
  };
  useEffect(() => {
    setData(studentListData.find((item) => item.id === id));
  }, [studentListData]);
  useEffect(() => {
    fetchPaymentDetails();
  }, [data]);

  // Handlers for EscalationSwitch
  const handleEscalationChange = (checked) => {
    setEscalationChecked(checked);
  };

  const handleExecutiveChange = (value) => {
    setSelectedExecutive(value);
  };

  const handleEscalate = () => {
    // Implement escalation logic here
    log.debug('Escalated to:', selectedExecutive);
  };

  // Fetch activities (replace with actual API call)
  useEffect(() => {
    setOpenActivities(Object.values(OpenActivities));
    setResolvedActivities(Object.values(ResolvedActivities));
  }, []);

  
  return (
    <Drawer
      title={`User ID: ${data?.userId}  |  DEAL ID: ${data?.id}`}
      onClose={() => setOpen(false)}
      open={open}
      width={"90vw"}
    >
      <div className="student-drawer-content">
        <div className="main-content">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Personal Data" key="1">
            <Flex justify="end">
              {activeEdit === "" && (
                <div
                  onClick={() => {
                    setActiveEdit("personal-details");
                  }}
                  className="section-edit-container"
                  style={{ cursor: "pointer", marginLeft: "auto" }}
                >
                  Edit
                </div>
              )}
            </Flex>
            {activeEdit !== "personal-details" && (
              <section>
                <div className="profile-section-personal-details-titles-wrapper">
                  <span className="profile-section-title">First Name : </span>
                  <span className="profile-section-value">
                    {data?.first_name}
                  </span>
                </div>
                <div className="profile-section-personal-details-titles-wrapper">
                  <span className="profile-section-title">Last Name : </span>
                  <span className="profile-section-value">{data?.last_name}</span>
                </div>
                <div className="profile-section-personal-details-titles-wrapper">
                  <span className="profile-section-title">Phone : </span>
                  <span className="profile-section-value">{data?.phone}</span>
                </div>
                <div className="profile-section-personal-details-titles-wrapper">
                  <span className="profile-section-title">Alt Phone : </span>
                  <span className="profile-section-value">{data?.phone2}</span>
                </div>
                <div className="profile-section-personal-details-titles-wrapper">
                  <span className="profile-section-title">Email : </span>
                  <span className="profile-section-value">{data?.email}</span>
                </div>

                <div className="profile-section-personal-details-titles-wrapper">
                  <span className="profile-section-title">Address: </span>
                  <span className="profile-section-value">
                    {data?.address
                      ?.split("<-->")
                      .map((area) => {
                        if (area != "undefined") return area;
                        return "";
                      })
                      .join(" ")}
                  </span>
                </div>
                <div className="profile-section-personal-details-titles-wrapper">
                  <span className="profile-section-title">City : </span>
                  <span className="profile-section-value">
                    {citys[data?.city_id]}
                  </span>
                </div>
              </section>
            )}
            {activeEdit === "personal-details" && (
              <Form
                layout="vertical"
                onFinish={(e) => {
                  const oldAddress = data.address.split("<-->");
                  const updatedData = {
                    userId: data.user_id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    phone: data.phone,
                    phone2: data.phone2,
                    email: data.email,
                    flat: oldAddress[0],
                    apartment: oldAddress[1],
                    street: oldAddress[2],
                    city_name: data.city_name,
                    locality: oldAddress[3],
                    zip: data.zip,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    user_type: "tutor",
                    ...e,
                    ...address,
                  };
                  updatePersonalDetails(updatedData);
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="first_name"
                      label="First Name"
                      className="profile-section-title"
                      initialValue={data?.first_name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter user first name",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter user first name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      className="profile-section-title"
                      initialValue={data?.last_name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter user last name",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter user last name" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={12}>
                  <Form.Item
                    name="phone"
                    label="Phone"
                    initialValue={data?.phone}
                    className="profile-section-title"
                    rules={[
                      {
                        required: true,
                        message: "Please enter user phone no",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter user phone no" />
                  </Form.Item>
                </Col> */}
                  <Col span={12}>
                    <Form.Item
                      name="phone2"
                      label="Alter Phone"
                      className="profile-section-title"
                      initialValue={data?.phone2}
                      rules={[
                        {
                          // required: true,
                          message: "Please enter user alter phone no",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter user alter phone no" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label="Email"
                      className="profile-section-title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter user email",
                        },
                      ]}
                      initialValue={data?.email}
                    >
                      <Input placeholder="Please enter user email" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="flat"
                      label="Flat No"
                      className="profile-section-title"
                      rules={[
                        {
                          required: false,
                          message: "Please enter user flat no",
                        },
                      ]}
                      initialValue={data?.address.split("<-->")[0]}
                    >
                      <Input placeholder="Please enter user flat no" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="apartment"
                      label="Apartment Name"
                      className="profile-section-title"
                      rules={[
                        {
                          required: false,
                          message: "Please enter user apartment name ",
                        },
                      ]}
                      initialValue={data?.address.split("<-->")[1]}
                    >
                      <Input placeholder="Please enter user apartment name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="street"
                      label="Street Name"
                      className="profile-section-title"
                      rules={[
                        {
                          required: false,
                          message: "Please enter user street name",
                        },
                      ]}
                      initialValue={data?.address.split("<-->")[2]}
                    >
                      <Input placeholder="Please enter user street name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="city_name"
                      label="City"
                      className="profile-section-title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter user city",
                        },
                      ]}
                      initialValue={data?.city_name}
                    >
                      <Select placeholder="Please enter user city" showSearch>
                        {Object.keys(citys).map((city_id) => (
                          <Option key={city_id} value={citys[city_id]}>
                            {citys[city_id]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="full_address"
                      label="Pincode"
                      className="profile-section-title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter user pincode",
                        },
                      ]}
                      // initialValue={data?.zip}
                    >
                      {/* <Input placeholder="Please enter user flat no" /> */}
                      <Autocomplete
                        apiKey={apiKey}
                        style={{ width: "95%" }}
                        className="ant-input css-dev-only-do-not-override-1uweeqc ant-input-outlined"
                        onPlaceSelected={(place) => {
                          let addressComponents = place.address_components;
                          let pinCode = addressComponents.find((component) =>
                            component.types.includes("postal_code")
                          );
                          let locality = addressComponents
                            .map((geo) => geo.long_name)
                            .join(", ");
                          let city = addressComponents.find(
                            (component) =>
                              component.types.includes("locality") &&
                              component.types.includes("political")
                          );
                          let state = addressComponents.find(
                            (component) =>
                              component.types.includes(
                                "administrative_area_level_1"
                              ) && component.types.includes("political")
                          );
                          let latitude = place.geometry.location.lat();
                          let longitude = place.geometry.location.lng();
                          log.info({
                            pincode: pinCode ? pinCode.short_name : " ",
                            city: city ? city.long_name : " ",
                            state: state ? state.long_name : " ",
                            locality: locality,
                            latitude: latitude,
                            longitude: longitude,
                            gmap_meta_data: JSON.stringify(addressComponents),
                          });
                          setAddress({
                            zip: pinCode ? pinCode.short_name : " ",
                            // city_name: city ? city.long_name : " ",
                            // state: state ? state.long_name : " ",
                            locality: locality,
                            latitude: latitude,
                            longitude: longitude,
                            gmap_meta_data: JSON.stringify(addressComponents),
                          });
                          // setAddress((prevAddress) => ({
                          //   ...prevAddress,
                          //   pincode: pinCode ? pinCode.short_name : " ",
                          //   city: city ? city.long_name : " ",
                          //   state: state ? state.long_name : " ",
                          //   locality: locality,
                          //   latitude: latitude,
                          //   longitude: longitude,
                          //   gmap_meta_data: JSON.stringify(addressComponents),
                          // }));
                        }}
                        options={{
                          types: [
                            // "hospital",
                            // "pharmacy",
                            // "bakery",
                            // "sublocality",
                            // ,
                            // "landmark",
                            // "locality",
                            // "postal_code",
                            // "postal_code_prefix",
                            // "postal_code_suffix",
                            // "postal_town",
                            // "street_address",
                            // "street_number",
                            // "sublocality",
                            // "sublocality_level_1",
                            // "sublocality_level_2",
                            // "sublocality_level_3",
                            // "sublocality_level_4",
                            // "sublocality_level_5",
                            // "subpremise",
                            // "town_square",
                          ],

                          componentRestrictions: { country: "IN" }, // Set country to "IN" for India
                        }}
                        defaultValue={data?.zip}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  wrapperCol={{
                    offset: 11,
                    span: 16,
                  }}
                >
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                    <Button
                      htmlType="button"
                      onClick={() => {
                        setActiveEdit("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            )}
          </TabPane>
          <TabPane tab="Requirements" key="2">
    <Flex justify="center" align="center">
      {activeEdit === "" && (
        <div
          onClick={() => {
            setActiveEdit("requirement");
            fetchDataSetDefaults();
          }}
          className="section-edit-container"
          style={{ cursor: "pointer", marginLeft: "auto" }}
        >
          Edit
        </div>
      )}
    </Flex>
    {activeEdit !== "requirement" && (
      <section>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Subjects : </span>
          <span className="profile-section-value">
            {data?.requirements}
          </span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Category Id : </span>
          <span className="profile-section-value">
            {courseCategories[data?.category_id]}
          </span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Stream Id : </span>
          <span className="profile-section-value">{data?.stream_id}</span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Gender Preference : </span>
          <span className="profile-section-value">
            {data?.tutor_gender_preference}
          </span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Days Per Week : </span>
          <span className="profile-section-value">
            {data?.how_many_days_in_a_week}
          </span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">When to start : </span>
          <span className="profile-section-value">
            {data?.when_you_like_to_start}
          </span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Fee Percentage : </span>
          <span className="profile-section-value">
            {data?.fee_percent ? data.fee_percent : 0} %
          </span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Fee Discuss : </span>
          <span className="profile-section-value">
            {data?.fee_discuss ? data.fee_discuss : 0} ₹
          </span>
        </div>
        <div className="profile-section-personal-details-titles-wrapper">
          <span className="profile-section-title">Tution Type : </span>
          <span className="profile-section-value">
            {data?.tution_type}
          </span>
        </div>
      </section>
    )}
    {activeEdit === "requirement" && (
      <Form
        layout="vertical"
        onFinish={(values) => {
          log.debug("Form Values: ", values);
          // API call for form submission
          setActiveEdit(""); // Reset edit mode
        }}
        initialValues={{
          course_category: data?.category_id,
          course_level: data?.level,
          course_stream: data?.stream_id,
          tutor_gender_preference: data?.tutor_gender_preference,
          how_many_days_in_a_week: data?.how_many_days_in_a_week,
          when_you_like_to_start: data?.when_you_like_to_start,
          FeePercentage: data?.fee_percent,
          fee_discuss: data?.fee_discuss,
          tution_type: data?.tution_type,
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Course Category" name={"course_category"}>
              <Select
                style={{ width: "100%" }}
                allowClear
                onClear={() => {
                  setSelectedCourseCategory(null);
                  setSelectedCourses([]);
                  setSelectedStream(null);
                  setStreamsData([]);

                  filterForm.setFieldsValue({
                    course: [],
                    course_stream: null,
                    course_level: null,
                  });
                }}
                placeholder="Select Course Category"
                onChange={(value) => {
                  filterForm.setFieldsValue({
                    course: [],
                    course_stream: null,
                    course_level: null,
                  });
                  setSelectedCourseCategory(value);
                  (async () => {
                    setSelectedCourses([]);
                    setSelectedStream(null);
                    setStreamsData([]);

                    if (value == 1) {
                      return;
                    }
                    await fetchCoursesOfCategory(value);
                  })();
                }}
              >
                {Object.keys(courseCategories).map((Category_id) => (
                  <Option key={Category_id} value={Category_id}>
                    {courseCategories[Category_id]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {selectedCourseCategory == 1 && (
            <Col span={12}>
              <Form.Item label="Level" name={"course_level"}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Level"
                  onChange={(value) => {
                    setStreamsData([]);
                    setSelectedStream(null);
                    setSelectedCourses([]);

                    filterForm.setFieldsValue({
                      course: [],
                      course_stream: null,
                    });
                    (async () => {
                      fetchCoursesOfCategory(
                        "11th Class, 12th Class".includes(value)
                          ? 4
                          : selectedCourseCategory
                      );
                    })();
                  }}
                >
                  {academicLevels.map((level) => (
                    <Option key={level} value={level}>
                      {level}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {streamsData?.length > 0 && (
            <Col span={12}>
              <Form.Item label="Course Stream" name={"course_stream"}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Course Stream"
                  onChange={(value) => {
                    filterForm.setFieldsValue({ course: [] });
                    setSelectedCourses([]);
                    setSelectedStream(value);
                  }}
                >
                  {streamsData.map((stream) => (
                    <Option key={stream.stream_id} value={stream.stream_id}>
                      {stream.stream_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {selectedStream && (
            <Col span={12}>
              <Form.Item label="Course" name={"course"}>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Course"
                  onChange={(value) => {
                    setSelectedCourses(value);
                  }}
                >
                  {streamsData
                    .find((stream) => stream.stream_id == selectedStream)
                    .courses.map((course) => (
                      <Option key={course} value={course}>
                        {course}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              name="tutor_gender_preference"
              label="Gender Preference"
              rules={[
                {
                  required: true,
                  message: "Please enter Gender Preference",
                },
              ]}
            >
              <Input placeholder="Enter Gender Preference" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="how_many_days_in_a_week"
              label="Days Per Week"
              rules={[
                {
                  required: true,
                  message: "Please enter Days Per Week",
                },
              ]}
            >
              <Input placeholder="Enter Days Per Week" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="when_you_like_to_start"
              label="When to start"
              rules={[
                {
                  required: true,
                  message: "Please enter When to start",
                },
              ]}
            >
              <Input placeholder="Enter When to start" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="FeePercentage"
              label="Fee Percentage"
              rules={[
                {
                  required: true,
                  message: "Please select fee percentage",
                },
              ]}
            >
              <Select placeholder="Please select fee percentage">
                <Select.Option value={50}>50%</Select.Option>
                <Select.Option value={100}>100%</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="fee_discuss"
              label="Fee discuss"
              rules={[
                {
                  required: true,
                  message: "Please enter discussed fees",
                },
              ]}
            >
              <Input placeholder="Enter discussed fees" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tution_type"
              label="Tuition Type"
              rules={[
                {
                  required: true,
                  message: "Tuition Type",
                },
              ]}
            >
              <Input placeholder="Tuition Type" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              htmlType="button"
              onClick={() => {
                setActiveEdit("");
              }}
            >
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    )}
  </TabPane>

          <TabPane tab="Payments" key="3">
            {paymentDetails && (
              <div>
                <Alert
                  style={{ margin: "20px 0" }}
                  message={
                    paymentDetails?.subscriptionData?.status_code === "SUCCESS"
                      ? "Subscription Status: Success"
                      : `Subscription Status: Pending - Email sent count: ${
                          paymentDetails?.subscriptionData?.invoice_sent_count ||
                          0
                        }`
                  }
                  type={
                    paymentDetails?.subscriptionData?.status_code === "SUCCESS"
                      ? "success"
                      : "error"
                  }
                  showIcon
                  action={
                    paymentDetails?.subscriptionData?.status_code ===
                    "SUCCESS" ? (
                      <Button
                        type="primary"
                        onClick={() => {
                          sendPaymentRecipt(
                            paymentDetails?.subscriptionData?.payment_id
                          );
                        }}
                      >
                        Send Reciept
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        onClick={() => {
                          // send email for payment
                          emailInitialSubcriptionPayment();
                        }}
                      >
                        Email Payment Link
                      </Button>
                    )
                  }
                />

                <Table
                  dataSource={paymentDetails?.orders}
                  columns={[
                    // {
                    //   title: "Payment ID",
                    //   dataIndex: "payment_id",
                    // },
                    {
                      title: "Order ID",
                      dataIndex: "order_id",
                      width: 90,
                    },
                    {
                      title: "Status",
                      dataIndex: "status_code",
                      width: 100,
                      render: (status) => (
                        <Tag
                          color={
                            status === "SUCCESS"
                              ? "green"
                              : status === "FAILURE"
                              ? "red"
                              : "blue"
                          }
                        >
                          {status === "SUCCESS" ? "SUCCESS" : "PENDING"}
                        </Tag>
                      ),
                    },

                    {
                      title: "Amount",
                      dataIndex: "custom_amount",
                      width: 120,
                      render(amount) {
                        return <span>{amount} Rs/-</span>;
                      },
                    },
                    // {
                    //   title: "Billing Amount",
                    //   dataIndex: "billing_amount",
                    //   width: 100,
                    //   render(amount) {
                    //     return (
                    //       <span>{amount} Rs/-</span>
                    //     );
                    //   }
                    // },
                    // {
                    //   title: "Deal ID",
                    //   dataIndex: "deal_id",
                    // },
                    {
                      title: "GST By User",
                      dataIndex: "gst_by_user",
                      width: 110,
                      render: (gst) => (gst ? "Yes" : "No"),
                    },
                    {
                      title: "Created At",
                      dataIndex: "created_at",
                      width: 120,
                      render: (date) => {
                        date = date?.split("T")[0];
                        return date;
                      },
                    },
                    {
                      title: "Updated At",
                      dataIndex: "updated_at",
                      width: 120,
                      render: (date) => {
                        date = date?.split("T")[0];
                        return date;
                      },
                    },
                    {
                      title: "Actions",
                      width: 175,
                      render: (_, order) => (
                        <Button
                          type="primary"
                          onClick={() => {
                            if (order.status_code === "SUCCESS") {
                              sendPaymentRecipt(order.payment_id);
                            } else {
                              emailCutomAmountPayment({
                                orderId: order.order_id,
                              });
                            }
                          }}
                        >
                          {order.status_code === "SUCCESS"
                            ? "Send Reciept"
                            : "Send Payment Link"}
                        </Button>
                      ),
                    },
                    {
                      title: "Request Count",
                      dataIndex: "invoice_sent_count",
                      width: 120,
                    },
                    {
                      title: "Payment Link",
                      dataIndex: "payment_link",
                      ellipsis: true,
                      // width:120,
                      render: (link) => (
                        <a href={link} target="_blank" rel="noreferrer">
                          {link}
                        </a>
                      ),
                    },
                  ]}
                />
              </div>
            )}
            <Flex align="center" justify="center" style={{ marginTop: "20px" }}>
              <Button
                type="primary"
                onClick={() => {
                  setCustomAmountRequest(true);
                }}
              >
                Add Payment
              </Button>
            </Flex>
            {customAmountRequest && (
              <Drawer
                title="Add Payment"
                onClose={() => {
                  setCustomAmountRequest(false);
                }}
                open={customAmountRequest}
                width={"40vw"}
              >
                <Form
                  layout="vertical"
                  onFinish={(values) => {
                    emailCutomAmountPayment(values);
                  }}
                >
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the amount",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Rs"
                      onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^0-9]/g, "");
                        e.target.value = filteredValue;
                      }}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a description",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item> */}
                  <Form.Item
                    label="Tax Paid"
                    name="gstByUser"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item>
                    <Space style={{ width: "100%" }}>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                      <Button
                        onClick={() => {
                          setCustomAmountRequest(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Drawer>
            )}
          </TabPane>

          <TabPane tab="Tutors" key="4">
          <div className="tutors-container">
            <Input.Search
              placeholder="Search tutors..."
              onSearch={(value) => {
                setSearchTerm(value);
                updateURL(appliedFilters);
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Form
              form={form}
              layout="vertical"
              onValuesChange={(_, allValues) => handleFilterChange(allValues)}
              initialValues={{ verified: null, gender: null }}
            >
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item label="Verified" name="verified">
                    <Select placeholder="Select verification status" allowClear>
                      <Option value="verified">Verified</Option>
                      <Option value="not_verified">Not Verified</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Gender" name="gender">
                    <Select placeholder="Select gender" allowClear>
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Distance Range" name="distance">
                    <Select placeholder="Select distance">
                      <Option value={1}>1 km</Option>
                      <Option value={5}>5 km</Option>
                      <Option value={10}>10 km</Option>
                      <Option value={20}>20 km</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Subjects" name="subjects">
                    <Select mode="multiple" placeholder="Select subjects">
                      <Option value="Math">Math</Option>
                      <Option value="Science">Science</Option>
                      <Option value="English">English</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Syllabus" name="syllabus">
                    <Select placeholder="Select syllabus">
                      <Option value="CBSE">CBSE</Option>
                      <Option value="ICSE">ICSE</Option>
                      <Option value="State Board">State Board</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Custom Filter" name="customFilter">
                    <Input placeholder="Enter custom filter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="filter-actions">
                <Button onClick={handleClearAll}>
                  Clear All
                </Button>
                <Button type="primary" onClick={() => console.log('Applied Filters:', appliedFilters)}>
                  Applied Filters
                </Button>
              </div>
            </Form>
            <div className="applied-filters">
              {Object.entries(appliedFilters).map(([filterKey, value]) => (
                shouldDisplayFilter(value) && (
                  <Tag
                    key={filterKey}
                    closable
                    onClose={() => handleTagClose(filterKey)}
                  >
                    {filterKey}: {value}
                  </Tag>
                )
              ))}
            </div>
            <div className="tutor-results">
              {/* Add your table or tutor results component here */}
            </div>
          </div>
          </TabPane>
        </Tabs>
      </div>

      <div className="side-panel ">
        <div className="escalation-section">
          <h3>Escalation</h3>
          <Space>
            <Switch checked={escalationChecked} onChange={handleEscalationChange} />
            <Select 
              value={selectedExecutive} 
              onChange={handleExecutiveChange}
              placeholder="Select executive"
            >
              {/* Add options here */}
            </Select>
            <Button onClick={handleEscalate}>Escalate</Button>
          </Space>
        </div>
        
        <div className="activity-section">
        <h3>Activity</h3>
        <Tabs defaultActiveKey="open">
          <Tabs.TabPane tab="Open" key="open">
            {openActivities.map(activity => (
              <Card 
                key={activity.id} 
                title={activity.executiveName} 
                extra={activity.timestamp}
              >
                {activity.description}
              </Card>
            ))}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Resolved" key="resolved">
            {resolvedActivities.map(activity => (
              <Card 
                key={activity.id} 
                title={activity.executiveName} 
                extra={activity.timestamp}
              >
                {activity.description}
              </Card>
            ))}
          </Tabs.TabPane>
        </Tabs>
        <Input.TextArea placeholder="Leave a comment..." />
        <Button type="primary">Submit</Button>
      </div>
      </div>
      </div>
    </Drawer>
  );
}

export default StudentDrawer;